@tailwind base;
@tailwind components;
@tailwind utilities;
/**
*
* Each layer has its own folder referecing scss files.
* 
* NB: Use #{!important} if you want to write !important overides for classes;
*/

@layer base {
  @import 'base';
}

@layer components {
  @import 'components';
}

html {
  scroll-behavior: smooth;
}

section {
  scroll-behavior: smooth;
}

@layer components {
  @import './modules/navigation';
}
