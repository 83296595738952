mux-player.mux-player::part(
    center play button gesture-layer time centered-layer
  ) {
  display: none;
}

mux-player.mux-player {
  pointer-events: none;
  user-select: none;
}

mux-player::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background: linear-gradient(360deg, #121212, #121212, transparent);
  z-index: 20;
}

/* // mux-player.mux-player::before {
//   content: '';
//   position: absolute;
//   z-index: 50;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 110px;
//   box-shadow: 2px 2px 10px 27px white inset;
//   z-index: 20;
// } */
