@keyframes navigating {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.navigating-animation {
  animation: navigating 0.5s ease infinite !important;
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@keyframes flash {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  50% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.group:hover .animate-sparkle {
  animation: flash 1s forwards;
}

@media (max-width: 768px) {
  .animate-sparkle {
    animation: flash 3s forwards;
    animation-iteration-count: infinite; /* Optional: repeat the animation */
  }
}
