.form-fields .input {
  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover,
  &:-internal-autofill-selected {
    -webkit-transition:
      background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s !important;
    transition:
      background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s !important;
    background: none !important;
  }

  &[type='text'],
  &[type='email'],
  &[type='phone'],
  &[type='password'],
  &[type='tel'] {
    @apply rounded-t-md bg-white p-[25px_12px_8px] text-[16px] text-charcoal-800 focus:bg-[#FAFAFA] disabled:border-b-[1px] disabled:bg-gray-100 disabled:text-charcoal-400 #{!important};
    -webkit-tap-highlight-color: transparent;
    appearance: none !important;

    &:disabled {
      @apply bg-gray-100 text-charcoal-400 #{!important};
      background-color: black;
    }
    &:-webkit-autofill {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;

      &:focus {
        -webkit-box-shadow: none !important;
      }
    }

    /* if has class dark */
    &.dark {
      @apply rounded-lg border border-[#EFE9DB] bg-[#1D1D1B] !p-2 text-[#EFE9DB] placeholder:text-[#EFE9DB] #{!important};

      &:-webkit-autofill {
        background-color: transparent !important;
        -webkit-box-shadow: 0 0 0 1000px #1d1d1b inset !important;

        &:focus {
          -webkit-box-shadow: none !important;
        }
      }
    }
  }

  &[type='checkbox'] {
    @apply h-6 w-6 rounded-[4px] border-2 border-charcoal-500 checked:border-yellow checked:bg-yellow [&~span>svg]:scale-[1.3] [&~span>svg]:fill-white #{!important};
    & ~ .ripple {
      display: none !important;
    }
    &::before {
      display: none !important;
    }
    /* @apply  #{!important}; */
  }

  &[type='textarea'] {
    @apply #{!important};
  }

  &[type='radio'] {
    @apply h-6 w-6 border-2 border-yellow [&~span>svg]:fill-yellow #{!important};
    & ~ .ripple {
      display: none !important;
    }
    &::before {
      display: none !important;
    }
  }

  &:-internal-autofill-selected {
    background-color: transparent !important;
    background: transparent !important;
  }
}

button.selectInput {
  @apply rounded-t-md p-[25px_12px_8px] text-[16px] [&[aria-expanded="true"]]:bg-[#FAFAFA] #{!important};

  & > span {
    @apply left-3 #{!important};
  }

  & ~ ul {
    @apply px-0 #{!important};
    & > li {
      @apply rounded-none #{!important};
    }
  }
}

.inputContainer {
  & .ripple {
    display: none !important;
  }
}

.inputLabel {
  @apply top-0.5 px-3 pt-1.5 text-[12px] text-charcoal-600 after:bottom-[1px] after:mx-[-11px] after:border-teal-700 peer-placeholder-shown:pt-0 peer-placeholder-shown:text-[16px] peer-focus:pt-1.5 peer-focus:text-[12px] peer-focus:text-teal-700 peer-disabled:text-charcoal-400 peer-[.border-red-500]:text-red-500 peer-[.border-red-500]:after:border-red-500 #{!important};
}
.inputLabelCheckbox {
  @apply text-sm font-normal text-charcoal md:text-base #{!important};
}

.peer:focus ~ .inputLabel.peer-focus\:after\:\!border-gray-900::after {
  @apply border-teal-700 #{!important};
}

.textareaLabel {
  @apply top-0.5 z-[1] px-3 pt-1.5 text-[12px] text-charcoal-600 before:absolute before:left-0 before:top-[-2px] before:z-[-1] before:h-[25px] before:w-[calc(100%_-_20px)] before:bg-[#FFF] after:bottom-[8px] after:mx-[-11px] after:border-teal-700 peer-placeholder-shown:pt-0 peer-placeholder-shown:text-[16px] peer-focus:pt-1.5 peer-focus:text-[12px] peer-focus:text-teal-700 peer-focus:before:bg-[#FAFAFA] peer-focus:after:border-teal-700 peer-disabled:text-charcoal-400 peer-[.border-red-500]:text-red-500 peer-[.border-red-500]:after:border-red-500 #{!important};
}

.peer:focus ~ .textareaLabel.peer-focus\:after\:\!border-gray-900::after {
  @apply border-teal-700 #{!important};
}

.form-fields textarea.input {
  @apply mt-0 rounded-t-md bg-white p-[25px_12px_8px] text-[16px] text-charcoal-800 focus:bg-[#FAFAFA] disabled:border-b-[1px] disabled:bg-gray-100 disabled:text-charcoal-400 #{!important};
  -webkit-tap-highlight-color: transparent;
  appearance: none !important;

  &:-webkit-autofill:not(:disabled) {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;

    &:focus {
      -webkit-box-shadow: none !important;
    }
  }
}

fieldset:disabled .form-fields input[type='text'],
fieldset:disabled .form-fields input[type='text']:-webkit-autofill,
fieldset:disabled .form-fields input[type='email']:-webkit-autofill,
fieldset:disabled .form-fields input[type='phone']:-webkit-autofill,
fieldset:disabled .form-fields input[type='tel']:-webkit-autofill,
fieldset:disabled .form-fields input[type='text']:-internal-autofill-selected {
  background-color: #f3f3f3 !important;
  -webkit-box-shadow: none !important;
}
