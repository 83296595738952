.swiper {
  &-button {
    &-outline {
      &-right {
        &::before {
          @apply absolute left-[-20px] z-[-100] h-40 w-40 rounded-full bg-black opacity-20;
          content: '';

          @media (min-width: 992px) {
            @apply hidden;
          }
        }
      }
      &-left {
        &::before {
          @apply absolute right-[-20px] z-[-100] h-40 w-40 rounded-full bg-black opacity-20;
          content: '';

          @media (min-width: 992px) {
            @apply hidden;
          }
        }
      }
    }
  }
}

@media (min-width: 420px) {
  .compare-table-button-container {
    display: none !important;
  }
}

/* Customize the pagination dots */
.most-recent-swiper-pagination .swiper-pagination-bullet {
  @apply h-3.5 w-3.5 rounded-full border border-charcoal bg-white #{!important};
}

.most-recent-swiper-pagination .swiper-pagination-bullet-active {
  @apply bg-charcoal #{!important};
}
